.HomeContainer {
  position: relative;
  padding: 0;
  width: 100%;
  min-height: 80vh;
  height: 100%;
  background-size: cover;
  background-image: url(https://res.cloudinary.com/donaticmedia/image/upload/v1649711615/assets/home2_btqkqt.jpg);
  background-position: center top;
}

.HomeCTA {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 2.5rem 0;
  text-align: center;
}

.HomeCTAContent {
  text-align: center;
  margin-bottom: 1rem;
}

.HowItWorksWrapper {
  padding-top: 3rem;
  padding-bottom: 3rem;
}