.border::after {
  position:absolute;
  content: " ";
  display:block;
  left:0;
  bottom:-20px;
  width:100%;
  border-style: solid;
  border-width: 0 100vw 20px 0;
  border-color: transparent rgba(0,0,0,1) transparent transparent;
}